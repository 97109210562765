export const SlideData = [
  {
    image: "/images/1.png",
  },
  {
    image: "/images/2.png",
  },
  {
    image: "/images/3.png",
  },
  {
    image: "/images/4.png",
  },
  {
    image: "/images/5.png",
  },
  {
    image: "/images/6.png",
  },
  {
    image: "/images/7.png",
  },
  {
    image: "/images/8.png",
  },
  {
    image: "/images/9.png",
  },
  {
    image: "/images/10.png",
  },
];
